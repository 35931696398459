const colorData = {
  background: {
    default: "Main UI backgrounds. It's used in most components and surfaces.",
    secondary: "Used for elements that need separation from the main canvas.",
    layered:
      "Background for elements that display over content (i.e. tooltips and dropdowns).",
    elevatedLow: "General low-opacity elevation layer. Good for hover states.",
    elevatedMid:
      "General mid-opacity elevation layer. Good for active states. ",
    inverted: "High contrast background used for high emphasis needs.",
  },
  text: {
    primary: "Default text & icon color.",
    secondary:
      "Lower-importance content. Used to create hierarchy with primary text color.",
    inverted: "Used for text and icons on inverted backgrounds.",
  },
  border: {
    default: "Default value for component borders and dividers.",
    subtle: "Secondary border to emphasize separation between elements.",
  },
}

export default colorData
