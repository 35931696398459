const grays = {
  white: "#FFF",
  black: "#000",
  gray000: "#F5F7FA",
  gray100: "#E3E6ED",
  gray200: "#D0D6E1",
  gray300: "#A2A9B8",
  gray400: "#8D95A5",
  gray500: "#676F7E",
  gray600: "#3E4451",
  gray700: "#272A34",
  gray800: "#1E2229",
  gray900: "#15181E",
}

export const lightPalette = {
  ...grays,

  red000: "#FEE7E8",
  red100: "#F8878B",
  red200: "#D0252A",
  red300: "#AE2428",
  red400: "#751518",

  orange000: "#FFEBE1",
  orange100: "#FB9B65",
  orange200: "#FA7A33",
  orange300: "#C24C0A",
  orange400: "#89380B",

  yellow000: "#FFFAE6",
  yellow100: "#FFEFB8",
  yellow200: "#FFC90A",
  yellow300: "#E5B300",
  yellow400: "#7A6000",

  green000: "#E5FFF9",
  green100: "#3CDDBA",
  green200: "#20B696",
  green300: "#167E64",
  green400: "#0D634E",

  blue000: "#E5F2FF",
  blue100: "#5186F0",
  blue200: "#2767E7",
  blue300: "#144BB8",
  blue400: "#133881",

  purple000: "#ECE5FF",
  purple100: "#845EED",
  purple200: "#603CC3",
  purple300: "#492E94",
  purple400: "#372173",

  pink000: "#FFE5F0",
  pink100: "#FB5397",
  pink200: "#E41469",
  pink300: "#C20F58",
  pink400: "#950942",
}

export const darkPalette = {
  ...grays,

  red000: "#FEE7E8",
  red100: "#F8878B",
  red200: "#D0252A",
  red300: "#AE2428",
  red400: "#751518",

  orange000: "#FFEBE1",
  orange100: "#FB9B65",
  orange200: "#FA7A33",
  orange300: "#C24C0A",
  orange400: "#89380B",

  yellow000: "#FFFAE6",
  yellow100: "#FFEFB8",
  yellow200: "#FFC90A",
  yellow300: "#E5B300",
  yellow400: "#7A6000",

  green000: "#E5FFF9",
  green100: "#3CDDBA",
  green200: "#20B696",
  green300: "#167E64",
  green400: "#0D634E",

  blue000: "#F5FAFF",
  blue100: "#5186F0",
  blue200: "#2767E7",
  blue300: "#144BB8",
  blue400: "#133881",

  purple000: "#ECE5FF",
  purple100: "#845EED",
  purple200: "#603CC3",
  purple300: "#492E94",
  purple400: "#372173",

  pink000: "#FFE5F0",
  pink100: "#FB5397",
  pink200: "#E41469",
  pink300: "#C20F58",
  pink400: "#950942",
}
