import React from "react"
import styled from "styled-components"
import { color, layout, space, typography } from "styled-system"

const StyledHeading = styled.h2`
  ${color}
  ${layout}
  ${space}
  ${typography}

  &.h1 {
    font-size: ${({ theme }) => theme.fontSizes.xl2};
    line-height: 1.25;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: ${({ theme }) => theme.fontSizes.xl3};
      line-height: 1.2;
    }
  }

  &.h2 {
    font-size: 1.75rem;
    line-height: 1.28571;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: ${({ theme }) => theme.fontSizes.xl2};
      line-height: 1.25;
    }
  }

  &.h3 {
    font-size: ${({ theme }) => theme.fontSizes.xl};
    line-height: 1.33333;
  }

  &.h4 {
    font-size: ${({ theme }) => theme.fontSizes.lg};
    line-height: 1.4;
  }

  &.h5 {
    font-size: ${({ theme }) => theme.fontSizes.md};
    line-height: 1.5;
  }
`
const Heading = ({ level, size, ...rest }) => {
  return <StyledHeading as={`h${level}`} className={size} {...rest} />
}

Heading.defaultProps = {
  color: "text.primary",
  fontWeight: "semiBold",
  level: "2",
  size: "h2",
}

export default Heading
