import styled from "styled-components"

const Code = styled.code`
  background: ${({ theme }) => theme.colors.background.secondary};
  border-radius: ${({ theme }) => theme.radii.default};
  color: ${({ theme }) => theme.colors.text.primary};
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-family: "SF Mono", SFMono-Regular, ui-monospace, "DejaVu Sans Mono",
    Menlo, Consolas, monospace;
  line-height: 1.25rem;
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  user-select: all;
`

export default Code
