import React from "react"
import { useTheme } from "styled-components"
import Box from "./SDS/Box"
import Code from "./Code"
import {
  FlexTable,
  FlexTableCell,
  FlexTableHeader,
  FlexTableRow,
} from "../components/FlexTable"

const SpacingTable = () => {
  const theme = useTheme()

  return (
    <FlexTable>
      <FlexTableHeader>
        <FlexTableCell width="30%">Theme Token</FlexTableCell>
        <FlexTableCell width="30%">Size</FlexTableCell>
        <FlexTableCell width="40%"></FlexTableCell>
      </FlexTableHeader>
      {theme.space.map((element, index) => {
        return (
          <FlexTableRow key={element}>
            <FlexTableCell width="30%">
              <Code>theme.space[{index}]</Code>
            </FlexTableCell>
            <FlexTableCell width="30%">{element}</FlexTableCell>
            <FlexTableCell width="40%">
              <Box width={element} height="1.5rem" bg="background.info" />
            </FlexTableCell>
          </FlexTableRow>
        )
      })}
    </FlexTable>
  )
}

export default SpacingTable
