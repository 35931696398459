import React from "react"
import { lightPalette, darkPalette } from "../../theme/colors"
import Box from "../SDS/Box"
import Code from "../Code"
import {
  FlexTable,
  FlexTableCell,
  FlexTableHeader,
  FlexTableRow,
} from "../FlexTable"
import { Heading } from "../SDS/Typography"
import colorData from "./colorData"

const swatchHeight = "3rem"

const CoreColors = () => {
  const backgroundColors = Object.entries(colorData.background)
  const textColors = Object.entries(colorData.text)
  const borderColors = Object.entries(colorData.border)

  return (
    <Box>
      <Box mb={8}>
        <Heading size="h4" level="3" my={2} mt={7}>
          Background/Canvas Colors
        </Heading>
        <FlexTable>
          <FlexTableHeader>
            <FlexTableCell width="25%">Example</FlexTableCell>
            <FlexTableCell width="35%">Theme Token</FlexTableCell>
            <FlexTableCell width="40%">Role</FlexTableCell>
          </FlexTableHeader>
          {backgroundColors.map((color, index) => {
            return (
              <FlexTableRow key={color[0]}>
                <FlexTableCell width="25%">
                  <Box
                    width="100%"
                    height={swatchHeight}
                    bg={`background.${color[0]}`}
                    borderRadius="default"
                  />
                </FlexTableCell>
                <FlexTableCell width="35%">
                  <Code>{`colors.background.${color[0]}`}</Code>
                </FlexTableCell>
                <FlexTableCell width="40%">{color[1]}</FlexTableCell>
              </FlexTableRow>
            )
          })}
        </FlexTable>
      </Box>
      <Box mb={8}>
        <Heading size="h4" level="3" my={2} mt={7}>
          Text/Foreground Colors
        </Heading>
        <FlexTable>
          <FlexTableHeader>
            <FlexTableCell width="25%">Example</FlexTableCell>
            <FlexTableCell width="35%">Theme Token</FlexTableCell>
            <FlexTableCell width="40%">Role</FlexTableCell>
          </FlexTableHeader>
          {textColors.map((color, index) => {
            return (
              <FlexTableRow key={color[0]}>
                <FlexTableCell width="25%">
                  <Box
                    width="100%"
                    height={swatchHeight}
                    bg={`text.${color[0]}`}
                    borderRadius="default"
                  />
                </FlexTableCell>
                <FlexTableCell width="35%">
                  <Code>{`colors.text.${color[0]}`}</Code>
                </FlexTableCell>
                <FlexTableCell width="40%">{color[1]}</FlexTableCell>
              </FlexTableRow>
            )
          })}
        </FlexTable>
      </Box>
      <Box mb={8}>
        <Heading size="h4" level="3" my={2} mt={7}>
          Border Colors
        </Heading>
        <FlexTable>
          <FlexTableHeader>
            <FlexTableCell width="25%">Example</FlexTableCell>
            <FlexTableCell width="35%">Theme Token</FlexTableCell>
            <FlexTableCell width="40%">Role</FlexTableCell>
          </FlexTableHeader>
          {borderColors.map((color, index) => {
            return (
              <FlexTableRow key={color[0]}>
                <FlexTableCell width="25%">
                  <Box
                    width="100%"
                    height={swatchHeight}
                    bg={`border.${color[0]}`}
                    borderRadius="default"
                  />
                </FlexTableCell>
                <FlexTableCell width="35%">
                  <Code>{`colors.border.${color[0]}`}</Code>
                </FlexTableCell>
                <FlexTableCell width="40%">{color[1]}</FlexTableCell>
              </FlexTableRow>
            )
          })}
        </FlexTable>
      </Box>
    </Box>
  )
}

export default CoreColors
