import React from "react"
import styled from "styled-components"
import { color, layout, space, typography } from "styled-system"

const StyledText = styled.p`
  ${color}
  ${layout}
  ${space}
  ${typography}

  &.xs {
    font-size: ${(props) => props.theme.fontSizes.xs};
    line-height: 1.33333;
  }

  &.sm {
    font-size: ${(props) => props.theme.fontSizes.sm};
    line-height: 1.25rem;
  }

  &.md {
    font-size: ${(props) => props.theme.fontSizes.md};
    line-height: 1.5;
  }

  &.lg {
    font-size: ${(props) => props.theme.fontSizes.lg};
    line-height: 1.5;
  }

  &.xl {
    font-size: ${(props) => props.theme.fontSizes.xl};
    line-height: 1.33333;
  }

  &.xl2 {
    font-size: ${(props) => props.theme.fontSizes.xl2};
    line-height: 1.375;
  }

  &.xl3 {
    font-size: ${(props) => props.theme.fontSizes.xl3};
    line-height: 1.2;
  }
}
`

const Text = ({ size, ...rest }) => {
  return <StyledText className={size} {...rest} />
}

Text.defaultProps = {
  color: "text.primary",
  fontSize: "md",
  fontWeight: "regular",
  size: "md",
}

export default Text
