import React from "react"
import { ThemeProvider } from "styled-components"
import GlobalStyles from "./theme/global-styles"
import { theme, darkTheme } from "./theme/theme"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      {element}
    </>
  </ThemeProvider>
)
