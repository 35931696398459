import React from "react"
import Code from "./Code"
import {
  FlexTable,
  FlexTableCell,
  FlexTableHeader,
  FlexTableRow,
} from "./FlexTable"
import { Text } from "./SDS/Typography"

const textVariants = {
  xl3: {
    fontSize: "2.5rem",
    lineHeight: "3rem",
  },
  xl2: {
    fontSize: "2rem",
    lineHeight: "2.75rem",
  },
  xl: {
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
  lg: {
    fontSize: "1.25rem",
    lineHeight: "1.75rem",
  },
  md: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  sm: {
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  xs: {
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
}

const TextVariants = () => {
  const textVariantsArray = Object.entries(textVariants)

  return (
    <FlexTable>
      <FlexTableHeader>
        <FlexTableCell width="20%">Size</FlexTableCell>
        <FlexTableCell width="40%">Example</FlexTableCell>
        <FlexTableCell width="40%">Font Size/Line Height</FlexTableCell>
      </FlexTableHeader>
      {textVariantsArray.map((size, index) => {
        return (
          <FlexTableRow>
            <FlexTableCell width="20%">{size[0]}</FlexTableCell>
            <FlexTableCell width="40%">
              <Text level={1} size={size[0]}>
                Text size {size[0]}
              </Text>
            </FlexTableCell>
            <FlexTableCell width="40%" display="flex" flexDirection="column">
              <Code>font-size: {size[1].fontSize}</Code>
              <Code>line-height: {size[1].lineHeight}</Code>
            </FlexTableCell>
          </FlexTableRow>
        )
      })}
    </FlexTable>
  )
}

export default TextVariants
