import styled from "styled-components"
import Box from "./SDS/Box"

export const FlexTable = styled(Box)`
  margin: ${({ theme }) => theme.space[6]} 0;
  width: 100%;
`

export const FlexTableRow = styled(Box)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.subtle};
  display: flex;
  padding: ${({ theme }) => theme.space[6]} 0;
`

export const FlexTableHeader = styled(FlexTableRow)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  padding: ${({ theme }) => theme.space[2]} 0;
`

export const FlexTableCell = styled(Box)`
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0 ${({ theme }) => theme.space[3]};
`
