import React from "react"
import { lightPalette, darkPalette } from "../../theme/colors"
import Box from "../SDS/Box"
import Code from "../Code"
import {
  FlexTable,
  FlexTableCell,
  FlexTableHeader,
  FlexTableRow,
} from "../FlexTable"

const swatchHeight = "3rem"

const Palette = () => {
  const lightColors = Object.entries(lightPalette)
  return (
    <FlexTable>
      <FlexTableHeader>
        <FlexTableCell width="25%">Swatch</FlexTableCell>
        <FlexTableCell width="20%">Name</FlexTableCell>
        <FlexTableCell width="20%">CSS Value</FlexTableCell>
        <FlexTableCell width="40%">Theme Token</FlexTableCell>
      </FlexTableHeader>
      {lightColors.map((color, index) => {
        return (
          <FlexTableRow key={color[0]}>
            <FlexTableCell width="25%">
              <Box
                width="100%"
                height={swatchHeight}
                bg={color[1]}
                borderRadius="default"
              />
            </FlexTableCell>
            <FlexTableCell fontWeight="medium" width="20%">
              {color[0]}
            </FlexTableCell>
            <FlexTableCell width="20%">
              <Code>{color[1]}</Code>
            </FlexTableCell>
            <FlexTableCell width="40%">
              <Code>colors.{color[0]}</Code>
            </FlexTableCell>
          </FlexTableRow>
        )
      })}
    </FlexTable>
  )
}

export default Palette
