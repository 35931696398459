import merge from "lodash/merge"
import { lightPalette, darkPalette } from "./colors"

/** A list of breakpoints accessible by key/value. */
export const breakpoints = {
  xs: "38rem", // 608px
  sm: "48rem", // 768px
  md: "60rem", // 960px
  lg: "75rem", // 1200px
  xl: "90rem", // 1440px
  xl2: "120rem", // 1920px
}

/**
 * We alias breakpoints onto the scale so that styled-system has access
 * to the named breakpoints as well as the scale
 */
const BREAKPOINTS_SCALE = Object.assign(
  [
    breakpoints.xs,
    breakpoints.sm,
    breakpoints.md,
    breakpoints.lg,
    breakpoints.xl,
    breakpoints.xl2,
  ],
  breakpoints
)

const theme = {
  // Border variations
  borders: ["1px solid", "2px solid"],

  // Breakpoints
  breakpoints: BREAKPOINTS_SCALE,

  // Color Palette & Usage Aliases
  colors: {
    ...lightPalette,

    background: {
      primary: lightPalette.white,
      secondary: lightPalette.gray000,
      layered: lightPalette.white,
      elevatedLow: "hsla(220, 32%, 60%, 0.08)",
      elevatedMid: "hsla(220, 30%, 60%, 0.13)",
      inverted: lightPalette.gray900,
      overlayBackdrop: "hsla(0, 0%, 0%, 0.75)",
      alert: lightPalette.red200,
      alertSubtle: lightPalette.red000,
      warning: lightPalette.yellow200,
      warningSubtle: lightPalette.yellow100,
      success: lightPalette.green300,
      successSubtle: lightPalette.green000,
      info: lightPalette.blue200,
      infoSubtle: lightPalette.blue000,
      action: lightPalette.purple200,
      actionSubtle: lightPalette.purple000,
      highlight: lightPalette.pink200,
      highlightSubtle: lightPalette.pink000,
    },

    border: {
      default: lightPalette.gray200,
      subtle: lightPalette.gray100,
      dark: lightPalette.gray300,
      alert: lightPalette.red200,
      warning: lightPalette.yellow200,
      success: lightPalette.green300,
      info: lightPalette.blue200,
      action: lightPalette.purple200,
      highlight: lightPalette.pink200,
    },

    text: {
      primary: lightPalette.gray900,
      secondary: lightPalette.gray500,
      onColor: lightPalette.white,
      disabled: lightPalette.gray400,
      inverted: lightPalette.white,
      alert: lightPalette.red200,
      warning: lightPalette.yellow400,
      success: lightPalette.green300,
      info: lightPalette.blue200,
      action: lightPalette.purple200,
      highlight: lightPalette.pink300,
    },

    gradients: {
      primary: "linear-gradient(90deg, #9d34a5 0%, #eb307c 80%)",
      secondary: "linear-gradient(90deg, #9a2ea9 0%, #7800ff 80%)",
      premium:
        "linear-gradient(90deg, rgba(253,119,52,1) 0%, rgba(235,48,124,1) 100%);",
      loading: `linear-gradient(to right, rgba(13, 15, 17, 0.04) 8%, rgba(13, 15, 17, 0.12) 18%, rgba(13, 15, 17, 0.04) 33%)`,
    },
  },

  // Font Families
  fonts: {
    body: "'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    heading:
      "'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },

  // Font size scale
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    xl2: "2rem",
    xl3: "2.5rem",
  },

  // Font weight scale
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },

  // Letter spacing variations
  letterSpacings: [0, ".015em", ".03em", "0.3px", "1px"],

  // Line height scale
  lineHeights: {
    typeSet: "normal",
    locked: 1,
    tight: 1.25,
    normal: 1.5,
  },

  // Line length
  lineLengths: {
    xs: "22.5rem",
    sm: "26.5rem",
    md: "30rem",
    lg: "37.5rem",
    xl: "46.875rem",
  },

  opacity: {
    disabled: 0.3,
  },

  // Border radius variations
  radii: {
    sharp: 0,
    default: "0.25rem",
    rounder: "0.5rem",
    pill: "20rem",
    circle: "50%",
  },

  // Element sizing
  sizes: {
    container: {
      xxs: "24rem",
      xs: "32rem",
      sm: "46rem",
      md: "60rem",
      lg: "80rem",
      xl: "120rem",
      full: "100%",
    },
    elements: {
      appBanner: {
        height: {
          desktop: "3rem",
          mobile: "4rem",
        },
      },
      header: {
        height: {
          desktop: "4rem",
          mobile: "3.75rem",
        },
      },
      searchSidebar: {
        width: {
          desktop: "16rem",
          mobile: "100vw",
        },
      },
    },
  },

  // Space scale for margin, padding, positioning
  space: [
    0,
    "0.25rem",
    "0.5rem",
    "0.75rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "2rem",
    "3rem",
    "4rem",
    "6rem",
    "8rem",
  ],

  // Box shadow variations
  shadows: {
    default: "0px 2px 6px 0px rgba(13, 15, 17, 0.12)",
    hover: "0px 2px 8px 0px rgba(13, 15, 17, 0.18)",
    overlay: "0 14px 30px 0 rgba(13, 15, 17,0.27)",
    dropdown: "0 12px 64px 0 rgba(13, 15, 17, 0.10)",
  },

  // Transition timing and curves
  transitions: {
    slow: "300ms ease-out",
    quick: "150ms ease-out",
  },

  // z-index levels based on element type
  zIndices: {
    default: 1,
    sticky: 300,
    fixed: 400,
    alert: 500,
    toast: 500,
    dropdown: 600,
    spinner: 700,
    popup: 800,
    modalOverlay: 950,
    modal: 999,
  },
}

const darkTheme = merge({}, theme, {
  colors: {
    ...darkPalette,

    background: {
      primary: darkPalette.gray900,
      secondary: darkPalette.gray800,
      layered: darkPalette.gray700,
      elevatedLow: "hsla(220, 100%, 100%, 0.05)",
      elevatedMid: "hsla(220, 100%, 100%, 0.11)",
      inverted: darkPalette.gray000,
      alert: darkPalette.red200,
      alertSubtle: darkPalette.red400,
      warning: darkPalette.yellow200,
      warningSubtle: darkPalette.yellow400,
      success: darkPalette.green300,
      successSubtle: darkPalette.green400,
      info: darkPalette.blue200,
      infoSubtle: darkPalette.blue400,
      action: darkPalette.purple200,
      actionSubtle: darkPalette.purple400,
      highlight: darkPalette.pink200,
      highlightSubtle: darkPalette.pink400,
    },

    border: {
      default: darkPalette.gray600,
      subtle: darkPalette.gray700,
      dark: lightPalette.gray500,
      alert: darkPalette.red200,
      warning: darkPalette.yellow200,
      success: darkPalette.green200,
      info: darkPalette.blue100,
      action: darkPalette.purple200,
      highlight: darkPalette.pink100,
    },

    text: {
      primary: darkPalette.gray100,
      secondary: darkPalette.gray400,
      onColor: darkPalette.white,
      disabled: darkPalette.gray500,
      inverted: darkPalette.gray900,
      alert: darkPalette.red100,
      warning: darkPalette.yellow100,
      success: darkPalette.green200,
      info: darkPalette.blue200,
      action: darkPalette.purple100,
      highlight: darkPalette.pink100,
    },

    gradients: {
      loading: `linear-gradient(to right, rgba(245, 247, 250, 0.06) 8%, rgba(245, 247, 250, 0.14) 18%, rgba(245, 247, 250, 0.06) 33%)`,
    },
  },
})

export { theme, darkTheme }
